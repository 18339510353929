import React from "react";
import { number, string } from "prop-types";
import { Link } from "gatsby";

import { navContainer, navButton, navButtonActive } from "./styles.module.scss";

export const Navbar = ({ currentPage, pageNumber, link }) => {
  const buttons = [];
  if (currentPage !== 1) {
    buttons.push(
      <Link
        key="previous"
        className={navButton}
        to={currentPage === 2 ? link : link + "page/" + (currentPage - 1)}
      >
        Prev
      </Link>
    );
  }
  for (let i = 1; i <= pageNumber; i++) {
    if (i !== currentPage) {
      buttons.push(
        <Link
          key={i}
          className={navButton}
          to={i === 1 ? link : link + "page/" + i}
        >
          {i}
        </Link>
      );
    } else {
      buttons.push(
        <div key={i} className={navButtonActive}>
          {i}
        </div>
      );
    }
  }
  if (currentPage !== pageNumber) {
    buttons.push(
      <Link
        key="next"
        className={navButton}
        to={link + "page/" + (currentPage + 1)}
      >
        Next
      </Link>
    );
  }
  return <div className={navContainer}>{buttons}</div>;
};

Navbar.propTypes = {
  currentPage: number,
  pageNumber: number,
  link: string,
};
